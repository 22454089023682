import React from "react"
import { connect } from "react-redux"
import { css } from "styled-components"
import tw from "twin.macro"
import Heading from "general/components/Heading"
import LoginForm from "form/components/LoginForm"
import { login } from "auth/redux"

function LoginPage(props) {
  const { login } = props

  return (
    <div css={tw`flex flex-col justify-center items-center min-h-screen`}>
      <div
        css={[
          tw`w-full max-w-xs bg-white px-8 py-8 shadow-md`,
          css`
            min-width: 320px;
            max-width: 420px;
          `,
        ]}
      >
        <div className="mb-8">
          <Heading>JCURVE Login</Heading>
        </div>
        <LoginForm onAction={login} />
      </div>
    </div>
  )
}

export default connect(undefined, { login })(LoginPage)
