import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { useForm } from "react-hook-form"
import tw from "twin.macro"
import { useSelector } from "react-redux"

import FormLayout from "general/components/FormLayout"
import TextField from "general/components/TextField"
import Button from "general/components/Button"
import Spinner from "general/components/Spinner"

export default function LoginForm(props) {
  const { onAction } = props
  const [isLoading, setIsLoading] = useState(false)
  const { handleSubmit, register, errors } = useForm()
  const authError = useSelector(state => state.firebase.authError)

  useEffect(() => {
    setIsLoading(false)
  }, [authError])

  const onSubmit = values => {
    setIsLoading(true)
    onAction(values)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormLayout>
        {authError && (
          <div css={tw`bg-red-100 py-4 px-4 mb-4 rounded-md`}>
            {authError.message}
          </div>
        )}
        <TextField
          type="text"
          name="email"
          label={"Email"}
          placeholder="Email Address"
          ref={register({
            required: "Required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address",
            },
          })}
        />
        <TextField
          type="password"
          name="password"
          label={"Password"}
          placeholder="Your password"
          ref={register({
            validate: value => value !== "admin" || "Nice try!",
          })}
        />
        <div className="mt-8">
          <Button
            fullWidth
            primary
            size="large"
            submit
            disabled={isLoading}
            loading={isLoading}
          >
            Login
          </Button>
        </div>
      </FormLayout>
    </form>
  )
}
